<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card bgCustom">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Select Mill" @change="(event) => updateMachTypeDropdown(event)" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="machTypeModel" :items="machTypeLists" default="" item-value="mach_type" item-text="descr" label="Select Mach. Type" @change="(event) => machineTypeOnChange(event)" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="machIDModel" :items="machIDLists" default="" item-value="mach_id" item-text="descr" label="Select Mach. Type" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateFrom" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateFrom" label="Start Date" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" :show-current="true" @click:date="$refs.dialog.save(dateFrom)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateTo" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateTo" label="End Date" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" :show-current="true" @click:date="$refs.dialog2.save(dateTo)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2">
                                <v-btn class="mr-4" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 cardGraph">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer1" style="height: 423px; width: 100%;"></div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'GBRK',
                disabled: false,
                href: '/admin/gbrk',
                },
                {
                text: 'Production Performa',
                disabled: false,
                href: '#',
                },
                {
                text: 'Machine Production Capacity',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            machTypeModel: '',
            machTypeLists: [],
            machIDModel: '',
            machIDLists: [],
            dateFrom: '',
            dateStartModal: false,
            dateTo: '',
            dateEndModal: false,
            radioModel: 'table',
            itemLists: [],
            headers: [
                { text: 'YEAR', value: 'tahun', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WEEK', value: 'minggu', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'MILL', value: 'mill', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'MACHINE', value: 'mesin', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'MACH. TOTAL CAPACITY', value: 'mach_cap_week', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'TOTAL ORDER LENGTH', value: 'TotPanjangOrder', align: 'right', class: 'primary--text blue lighten-5' }
            ],
            searchItem: '',
            loadingDatatable: false,
            dateFromFormatted: '',
            dateToFormatted: ''
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardGraph").style.display = "none";

            this.dateFrom = this.currentDate()
            this.dateTo = this.currentDate()

            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/OrderCapacity`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.millLists = res.data.mill
                this.machTypeLists = res.data.machType
                this.$store.dispatch('setOverlay', false)
            })

        },

        async getData(){

            if(this.millModel == null || this.millModel == ''){
                Swal.fire({
                        text: 'Please Select Mill',
                        icon: 'error',
                    })
                return false
            }

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardGraph").style.display = "none";

            if (this.dateFrom) {
                this.dateFromFormatted = new Date(this.dateFrom).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateFromFormatted = ''
            }

            if (this.dateTo) {
                this.dateToFormatted = new Date(this.dateTo).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateToFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/OrderCapacity/getData`, { 

                mill: this.millModel ? this.millModel : '',
                machType: this.machTypeModel ? this.machTypeModel : '',
                dateFrom: this.dateFromFormatted ? this.dateFromFormatted : '',
                dateTo: this.dateToFormatted ? this.dateToFormatted : '',

            },
                {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.$store.dispatch('setOverlay', false)
                this.getchart(res.data.arr_list1, res.data.arr_list2, res.data.arr_list3, res.data.arr_list4, "", "")
                $(".cardGraph").show
                console.log(res.data)

            })

        },

        async updateMachTypeDropdown(id){
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/OrderCapacity/getMachineByMill?mill=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.machTypeLists = []
                    this.machTypeLists = res.data.machType
                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/OrderCapacity/getMachineByMill?mill=all`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.machTypeLists = []
                    this.machTypeLists = res.data.machType
                })
            }
        },

        async machineTypeOnChange(id){
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/OrderCapacity/getMachineIDByType?machType=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.machIDLists = []
                    this.machIDLists = res.data.result
                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/OrderCapacity/getMachineIDByType?machType=all`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.machIDLists = []
                    this.machIDLists = res.data.result
                })
            }
        },

        getchart(dt1, dt2, dt3, dt4, title, subtitle){
            var chart = new CanvasJS.Chart('chartContainer1', {            
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                title:{
                    text: title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                subtitles:[
                    {
                        text: subtitle,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                toolTip:{
                    shared:true,
                    yValueFormatString: "#,###",
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    fontSize: 11
                },
                axisX: {
                    // labelFormatter: function (e) {
                    //     return (new Date(e.value - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                    // },
                    interval: 1,
                    intervalType: "day"
                },
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "Length (M)",
                    labelFormatter: this.addSymbols,
                },

                data: [
                    { 
                        type: "column",
                        name: "Plan",   
                        showInLegend: true,
                        color: "#FFC300",
                        dataPoints: dt3
                    },
                    {
                        type: "column",
                        name: "Production",
                        showInLegend: true,
                        dataPoints: dt4
                    },
                    {
                        type: "line",
                        name: "Capacity",
                        showInLegend: true,
                        color: "#01579B",
                        dataPoints: dt1
                    },
                    {
                        type: "line",
                        name: "Target",
                        showInLegend: true,
                        color: "#D32F2F",
                        dataPoints: dt2
                    },
                ]
            })
            chart.render();
        },

        currentDate() {
            const current = new Date();
            const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            return date;
        }

    }
    
}
</script>

<style scoped>

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: linear-gradient(to right, #fff, #f1f1f1) !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }

</style>